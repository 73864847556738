<template>
  <div>
    <h3>
      User Management
      <hr />
    </h3>
    <FormErrorAlert
      v-if="errors.length > 0"
      :errors="errors"
      :formRef="this.$refs.userSearch"
    />
    <ValidationObserver ref="userSearch">
      <div class="card">
        <div class="card-header">
          <div class="card-title mb-0">Admin User Search</div>
        </div>
        <div class="card-body">
          <form @submit.prevent="onSubmit">
            <div class="row mb-2">
              <div class="col-md-4">
                <TextInput
                  rules="min:2|customerName|max:30"
                  name="First Name"
                  id="searchFirstName"
                  v-model="searchCriteria.firstName"
                />
              </div>
              <div class="col-md-4">
                <TextInput
                  rules="min:2|customerName|max:30"
                  name="Last Name"
                  id="searchLastName"
                  v-model="searchCriteria.lastName"
                />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-4">
                <TextInput
                  rules="min:3|username|max:20"
                  name="Username"
                  id="searchUsername"
                  v-model="searchCriteria.username"
                />
              </div>
              <div class="col-md-4">
                <TextInput
                  rules="min:3|max:256"
                  name="Email"
                  id="searchEmail"
                  v-model="searchCriteria.email"
                />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-4">
                <label for="locations"> Role </label>
                <multiselect
                  v-model="selectedRoles"
                  label="name"
                  track-by="id"
                  :multiple="true"
                  id="searchRole"
                  ref="searchRole"
                  :clear-on-select="false"
                  :preserve-search="true"
                  open-direction="bottom"
                  placeholder="Select Role(s)"
                  :options="roles"
                >
                  <template slot="tag" slot-scope="{ option, remove }">
                    <span class="multiselect__tag" :key="option.id">
                      <span>{{ option.name }}</span>
                      <i
                        tabindex="1"
                        @keypress.enter.prevent="remove(option)"
                        @mousedown.prevent="remove(option)"
                        class="multiselect__tag-icon"
                      ></i>
                    </span> </template
                ></multiselect>
              </div>
              <div class="col-md-4">
                <label for="locations"> Associated Parks </label>
                <multiselect
                  v-model="selectedLocations"
                  label="longName"
                  track-by="id"
                  :multiple="true"
                  id="searchLocations"
                  ref="searchLocations"
                  :clear-on-select="false"
                  :preserve-search="true"
                  open-direction="bottom"
                  placeholder="Select Park(s)"
                  :options="locations"
                >
                  <template slot="tag" slot-scope="{ option, remove }">
                    <span class="multiselect__tag" :key="option.id">
                      <span>{{ option.longName }}</span>
                      <i
                        tabindex="1"
                        @keypress.enter.prevent="remove(option)"
                        @mousedown.prevent="remove(option)"
                        class="multiselect__tag-icon"
                      ></i>
                    </span> </template
                ></multiselect>
              </div>
              <div class="col-md-3">
                <label for="searchStatus">Status</label>
                <select
                  class="form-control form-control-lg"
                  id="searchStatus"
                  v-model="searchCriteria.userStatusId"
                >
                  <option
                    v-for="(status, index) in statuses"
                    :key="index"
                    :value="status.id"
                  >
                    {{ status.name }}
                  </option>
                </select>
              </div>
            </div>
            <button type="submit" class="btn btn-lg btn-primary mt-4 mr-2">
              Search
            </button>
            <button
              type="button"
              class="btn btn-cancel btn-lg mr-2 mt-4"
              @click="resetForm"
            >
              Reset
            </button>
            <button
              role="button"
              class="btn btn-lg btn-primary mt-4"
              @click="addUser"
              :disabled="!checkPermission('UserManagementUserManagement')"
            >
              <i class="fa fa-plus-circle"></i>
              INVITE NEW ADMIN USER
            </button>
          </form>
        </div>
      </div>
    </ValidationObserver>
    <div class="card">
      <div class="card-header">
        <div class="card-title mb-0">Results</div>
      </div>
      <div class="card-body">
        <div class="d-flex mb-2">
          <div class="align-self-center">Items Per Page:</div>
          <div class="ml-2">
            <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
              aria-label="Items Per Page"
            ></b-form-select>
          </div>
        </div>
        <b-table
          show-empty
          ref="table"
          striped
          :fields="fields"
          :items="loadData"
          :current-page="currentPage"
          :per-page="perPage"
          stacked="md"
          small
          bordered
          sort-icon-left
          class="mt-4"
        >
          <template v-slot:cell(name)="data">
            {{ data.item.firstName }} {{ data.item.lastName }}
          </template>

          <template v-slot:cell(locations)="data">
            <div
              v-for="(location, index) in data.item.locations"
              :key="location.id"
            >
              <div v-if="index < 5">
                {{ location.locationName }}
              </div>

              <b-collapse :id="'locations' + data.item.id" v-if="index >= 5">
                {{ location.locationName }}
                <br />
              </b-collapse>
              <b-button
                v-if="
                  data.item.locations.length > 5 &&
                    data.item.locations.length - 1 == index
                "
                v-b-toggle="'locations' + data.item.id"
                variant="link"
                ><span class="when-open text-primary">Show less</span
                ><span class="when-closed text-primary"
                  >Show more...</span
                ></b-button
              >
            </div>
          </template>

          <template v-slot:cell(id)="data">
            <div
              v-if="
                data.item.userRoleId != 1 && data.item.userRoleId >= +userRoleId
              "
            >
              <button
                class="btn btn-primary btn-sm mr-2 my-1"
                @click="modify(data.item.id)"
                v-if="data.item.userStatusId == 1"
                :disabled="!checkPermission('UserManagementUserManagement')"
              >
                Modify
              </button>
              <button
                class="btn btn-primary btn-sm mr-2 my-1"
                @click="deactivateUser(data.item)"
                v-if="data.item.userStatusId == 1"
                :disabled="!checkPermission('UserManagementUserManagement')"
              >
                Deactivate
              </button>
              <button
                class="btn btn-primary btn-sm mr-2 my-1"
                @click="activateUser(data.item)"
                v-if="data.item.userStatusId == 2"
                :disabled="!checkPermission('UserManagementUserManagement')"
              >
                Activate
              </button>
              <button
                class="btn btn-primary btn-sm mr-2 my-1"
                @click="deleteUser(data.item)"
                v-if="data.item.userStatusId != 3"
                :disabled="!checkPermission('UserManagementUserManagement')"
              >
                Delete
              </button>
            </div>
          </template>
        </b-table>
        <div class="d-flex">
          <div class="mr-auto">
            <div v-if="totalRows > 0">
              Showing
              {{ perPage * currentPage - perPage + (totalRows > 0 ? 1 : 0) }} to
              {{ perPageDisplay }} of {{ totalRows }} entries
            </div>
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>

      <div class="card-body text-center" v-if="loading">
        <div class="my-3">
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      </div>
    </div>
    <b-modal id="deleteModal" title="Delete User" @ok="confirmDelete">
      <div class="font-weight-bold" v-if="userToDelete">
        Are you sure you want to delete the user {{ userToDelete.firstName }}
        {{ userToDelete.lastName }}?
      </div>
      <br />
      <div></div>
      <template #modal-footer="{ ok, cancel }">
        <button type="buttom" @click="ok()" class="btn btn-primary">
          Confirm Delete
        </button>
        <button type="buttom" @click="cancel()" class="btn">Cancel</button>
      </template>
    </b-modal>
    <b-modal
      id="deactivateModal"
      title="Confirm Profile Deactivation"
      @ok="confirmDeactivate"
    >
      <div class="font-weight-bold">
        <p>
          If you deactivate this user they will no longer have access to the
          admin section of the parks application. The user will still show in
          this user search section and could later be reactivated with the same
          access level. Confirm Deactivate to proceed. Are you sure you want to
          deactivate this user.
        </p>
      </div>
      <p v-if="userToDeactivate">
        Name: {{ userToDeactivate.firstName }} {{ userToDeactivate.lastName }}
      </p>
      <br />
      <div></div>
      <template #modal-footer="{ ok, cancel }">
        <button type="buttom" @click="ok()" class="btn btn-primary">
          Confirm Deactivate
        </button>
        <button type="buttom" @click="cancel()" class="btn">Cancel</button>
      </template>
    </b-modal>
    <b-modal
      id="activateModal"
      title="Confirm Profile Activation"
      @ok="confirmActivation"
    >
      <div class="font-weight-bold">
        <p>
          If you activate this user they will again have access to the admin
          section of the parks application. The user will be granted the same
          role and park access level in place prior to deactivation. Confirm
          Activate to proceed.
        </p>
      </div>
      <p v-if="userToActivate">
        Name: {{ userToActivate.firstName }} {{ userToActivate.lastName }}
      </p>
      <br />
      <div></div>
      <template #modal-footer="{ ok, cancel }">
        <button type="buttom" @click="ok()" class="btn btn-primary">
          Confirm Activate
        </button>
        <button type="buttom" @click="cancel()" class="btn">Cancel</button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import AdminUserService from "@/services/admin/AdminUserService.js";
import AdminLocationService from "@/services/admin/AdminLocationService.js";
import TextInput from "@/validation/TextInput.vue";
import Multiselect from "vue-multiselect";
import checkPermissionMixin from "@/mixins/PermissionCheckMixin.js";

export default {
  name: "AdminUserSearch",
  title: "Admin - User Management",
  mixins: [checkPermissionMixin],
  components: {
    ValidationObserver,
    FormErrorAlert,
    TextInput,
    Multiselect
  },
  data() {
    return {
      errors: [],
      statuses: [],
      locations: [],
      selectedLocations: [],
      roles: [],
      selectedRoles: [],
      loading: false,
      showMoreId: null,
      userToDelete: {},
      userToDeactivate: {},
      userToActivate: {},
      totalRows: 10,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      fields: [
        {
          key: "name",
          label: "Name",
          sortable: true
        },
        {
          key: "email",
          label: "Email",
          sortable: true
        },
        { key: "userRoleName", label: "Role", sortable: true },
        { key: "locations", label: "Park Access", sortable: true },
        { key: "userStatusName", label: "Status", sortable: true },
        { key: "id", sortable: false, label: "Actions/Options" }
      ]
    };
  },
  computed: {
    perPageDisplay() {
      if (this.perPage * this.currentPage > this.totalRows) {
        return this.totalRows;
      }
      if (this.totalRows > this.perPage) {
        return this.perPage * this.currentPage;
      }
      return this.totalRows;
    },
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    userRoleId() {
      return this.$store.getters["auth/userRoleId"];
    },
    searchCriteria() {
      return (
        this.$store.getters["admin/userSearchParams"] ?? { userStatusId: 1 }
      );
    }
  },
  methods: {
    onSubmit() {
      this.$refs.userSearch.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.userSearch.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.userSearch.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          this.$store.dispatch("alert/clear");
          this.$refs.table.refresh();
          this.$store.commit("admin/setUserSearchParams", {
            ...this.searchCriteria
          });
        }
      });
    },
    loadData(ctx) {
      let paging = {
        pageNumber: ctx.currentPage,
        itemsPerPage: ctx.perPage,
        sortField: ctx.sortBy,
        sortDescending: ctx.sortDesc
      };
      const userService = new AdminUserService(this.tenantId);

      this.searchCriteria.locationIds = this.selectedLocations.map(l => l.id);
      this.searchCriteria.roleIds = this.selectedRoles.map(r => r.id);

      return userService.search(this.searchCriteria, paging).then(response => {
        this.currentPage = response.searchResults.pageNumber;
        this.totalRows = response.searchResults.totalItems;
        return response.searchResults.data;
      });
    },
    resetForm() {
      this.resetSearchCriteria();
      this.$refs.table.refresh();
    },
    deleteUser(item) {
      this.userToDelete = item;
      this.$bvModal.show("deleteModal");
    },
    confirmDelete() {
      const userService = new AdminUserService(this.tenantId);
      userService.deleteUser(this.userToDelete.id).then(response => {
        this.userToDelete = null;
        this.$refs.table.refresh();
        if (response?.statusCode == "Success") {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-success",
            message: "Admin user has been deleted.",
            layer: "admin"
          });
        } else {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: "Something went wrong...",
            layer: "admin"
          });
        }
      });
    },
    deactivateUser(item) {
      this.userToDeactivate = item;
      this.$bvModal.show("deactivateModal");
    },
    confirmDeactivate() {
      const userService = new AdminUserService(this.tenantId);
      userService.deactivateUser(this.userToDeactivate.id).then(response => {
        this.userToDeactivate = null;
        this.$refs.table.refresh();
        if (response?.statusCode == "Success") {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-success",
            message: "Admin user has been deactivated.",
            layer: "admin"
          });
        } else {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: "Something went wrong...",
            layer: "admin"
          });
        }
      });
    },
    activateUser(item) {
      this.userToActivate = item;
      this.$bvModal.show("activateModal");
    },
    confirmActivation() {
      const userService = new AdminUserService(this.tenantId);
      userService.activateUser(this.userToActivate.id).then(response => {
        this.userToActivate = null;
        this.$refs.table.refresh();
        if (response?.statusCode == "Success") {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-success",
            message: "Admin user has been activated.",
            layer: "admin"
          });
        } else {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: "Something went wrong...",
            layer: "admin"
          });
        }
      });
    },
    addUser() {
      this.$router.push("/admin/user-add").catch(() => {});
    },
    modify(id) {
      this.$router.push(`/admin/user-modify/${id}`).catch(() => {});
    },
    getLocations() {
      const locationService = new AdminLocationService(this.tenantId);
      locationService
        .getLocations()
        .then(response => (this.locations = response.data));
    },
    getStatuses() {
      const userService = new AdminUserService(this.tenantId);
      userService
        .getUserStatuses()
        .then(response => (this.statuses = response.data));
    },
    getRoles() {
      const userService = new AdminUserService(this.tenantId);
      userService.getUserRoles().then(response => (this.roles = response));
    },
    resetSearchCriteria() {
      this.$store.commit("admin/setUserSearchParams", { userStatusId: 1 });
      this.selectedRoles = [];
      this.selectedLocations = [];
    }
  },
  mounted() {
    this.getLocations();
    this.getStatuses();
    this.getRoles();
    this.$refs.searchRole.$refs.search.setAttribute("autocomplete", "off");
    this.$refs.searchLocations.$refs.search.setAttribute("autocomplete", "off");
    this.$refs.table.refresh();
  }
};
</script>
<style scoped>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
.modal-title {
  font-weight: bold;
}
</style>
